import React from 'react'
import SingleCallOut from './singleCallOut'

const callOutHolder = ({data}) => {
  return (
  <div className="callout-holder">
    <div className="row">
      {data.callouts && data.callouts.map((item,key) => 
          <div className="col-md-6 text-center callout-container" key={'callout' + key}>
              <SingleCallOut data={data.callouts[key]} />
          </div>
        )
      }
    </div>
  </div>
  )
}

export default callOutHolder